<template>
  <div>
    <div class="my-6">
      <h3 class="font-bold">Buscar Pedidos</h3>
      <span class="text-sm text-gray-500">Aqui você pode ver detalhes do pedido. Basta digitar o código da
        transação no campo abaixo.
      </span>
    </div>
    <div class="card shadow-sm border p-8 bg-white">
      <div class="flex p-2 gap-2 items-center lg:flex-row flex-col">
        <div class="flex-1 w-full">
          <Input v-model="code" @keyup.enter="getPedidos" placeholder="Código do pedido" label="Código do pedido"
            type="default" class="w-full" />
        </div>
        <div class="flex">
          <button
            class="bg-primary rounded-md shadow-md transform px-5 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50 py-2 mr-1"
            @click="getPedidos">
            <label class="text-white cursor-pointer" for="logo3">Buscar</label>
          </button>
          <button
            class="bg-primary rounded-md shadow-md transform px-5 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50 py-2"
            @click="showCamera">
            <label class="text-white cursor-pointer" for="logo3">
              <fa class="text-white" :icon="`fa-solid fa-camera`" size="lg" />
            </label>
          </button>
        </div>
      </div>
    </div>

    <p>{{ erro }}</p>
    <div v-if="camera" class=" flex justify-center items-center">
      <StreamBarcodeReader @decode="onDecode"></StreamBarcodeReader>
    </div>

    <div v-if="qrcodeNaoEncontrado"
      class="text-center font-medium flex justify-center items-center opacity-75 my-2 mt-5">
      {{ qrcodeNaoEncontrado }}
      <i class="ml-3 gg-ghost-character"></i>
    </div>

    <div class="card shadow-sm border p-8 bg-white mt-3" v-if="gotData">
      <Accordion v-for="(d, index) in data" v-bind:key="d.transacao" :title="`Pedido nº: ${d.pedido}`">
        <div class="flex my-2">
          <button
            class="bg-primary rounded-md shadow-md transform px-2 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50 py-1"
            @click="toggle">
            <label class="text-white cursor-pointer" for="logo3">{{
              expand ? "Fechar todos" : "Expandir todos"
            }}</label>
          </button>
          <div class="flex-1 flex justify-end gap-4"> 
            <button
              class="bg-primary rounded-md shadow-md transform px-2 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50 py-1"
              @click="searchInFila(d.transacao)">
              <label class="text-white cursor-pointer" for="logo3">Procurar na Fila</label>
            </button>

            <button
              class="bg-primary rounded-md shadow-md transform px-2 flex justify-center items-center font-bold cursor-pointer disabled:opacity-50 py-1"
              @click="searchInCatraca(d.transacao)">
              <label class="text-white cursor-pointer" for="logo3">Procurar na Catraca</label>
            </button>
          </div>
        </div>

        <Accordion title="Dados da Compra" :expand="expand">
          <div class="flex flex-col p-2 m-2 overflow-x-auto">
            <div class="flex gap-2">
              <b>Transação nº:</b>
              <span class="capitalize">{{ d.transacao }}</span>
            </div>
            <div class="flex gap-2">
              <b>Data:</b>
              <span class="capitalize">{{
                new Date(d.data).toLocaleDateString("pt-BR")
              }}</span>
            </div>
            <div class="flex gap-2">
              <b>Hora:</b>
              <span class="capitalize">{{
                new Date(d.data).toLocaleTimeString("pt-BR")
              }}</span>
            </div>

            <!-- <div class="flex gap-2">
              <b>ID Envio:</b>
              <span class="capitalize">{{ d["id_envio"] }}</span>
            </div>-->
            <div class="flex gap-2">
              <b>Status do Pedido:</b>
              <span class="capitalize" v-if="d['status_pedido'] === 1"> Voucher Ativo </span>
              <span class="capitalize" v-else-if="d['status_pedido'] === 2"> Voucher Cancelado </span>
            </div><!--
            <div class="flex gap-2">
              <b>Status da Entrega:</b>
              <span class="capitalize">{{ d["status_entrega"] }}</span>
            </div> -->
            <!-- -->
            <div class="flex gap-2">
              <b>Gateway Pagamento:</b>
              <span class="capitalize">{{ d.id_gateway_pagamento[0].nome }}</span>
            </div>

            <div class="flex gap-2">
              <b>Vendedor:</b>
              <span class="capitalize">{{
                d.vendedor
              }}</span>
            </div>
          </div>
        </Accordion>

        <Accordion title="Dados do(s) Produto(s)" :expand="expand">
          <Accordion v-for="(v, vp) in data[index].venda_produto" v-bind:key="vp" :title="`${v.produto[0].detalhes}`"
            :expand="expand">

            <div class="w-full flex flex-col p-2 overflow-x-auto">
              <div class="flex gap-2">
                <b>Nome: </b>
                <span>{{ v.produto[0].detalhes }}</span>
              </div>
              <div class="flex gap-2">
                <b>SKU:</b>
                <span class="capitalize">{{ v.produto[0].sku_magento }}</span>
              </div>
              <!-- <div class="flex gap-2">
                <b>Sexo:</b>
                <span class="capitalize">{{ v.produto[0].sexo }}</span>
              </div>-->
              <div class="flex gap-2">
                <b>Valor original:</b>
                <span class="capitalize">{{ toMoney(d.valor_original) }}</span>
              </div>
              <div class="flex gap-2">
                <b>Valor da loja:</b>
                <span class="capitalize">{{ toMoney(d.valor_loja) }}</span>
              </div>

              <div class="flex gap-2">
                <b>Setor: </b>
                <span class="capitalize">{{
                  v.produto[0].setor[0].nome
                }}</span>
              </div>
              <!-- <div class="flex gap-2">
                <b>ID do Subtipo: </b>
                <span class="capitalize">{{
                  v.produto[0].id_subtipo_produto[0].id
                }}</span>
              </div>
              <div class="flex gap-2">
                <b>Lote: </b>
                <span class="capitalize">{{ v.produto[0].lote }}</span>
              </div>-->
              <div class="flex gap-2">
                <b>PDV: </b>
                <span class="capitalize">{{ v.produto[0].pdv[0].nome }}</span>
              </div>
            </div>
          </Accordion>
        </Accordion>

        <Accordion title="Dados do Evento" :expand="expand">
          <div class="flex flex-col p-2 m-2 overflow-x-auto" v-for="(v, vi) in vendaProduto[index].produto"
            v-bind:key="vi">
            <!-- <div class="flex gap-2">
              <b>id:</b>
              <span class="capitalize">{{ v.id_evento[vi].id }}</span>
            </div>-->
            <div class="flex gap-2">
              <b>Nome:</b>
              <span class="capitalize">{{ v.id_evento[vi].nome }}</span>
            </div>
            <div class="flex gap-2">
              <b>Data Início:</b>
              <span class="capitalize">{{
                new Date(v.id_evento[vi].data_inicio).toLocaleDateString("pt-BR")
              }}</span>
            </div>
            <div class="flex gap-2">
              <b>Data Fim:</b>
              <span class="capitalize">{{
                new Date(v.id_evento[vi].data_fim).toLocaleDateString("pt-BR")
              }}</span>
            </div>
          </div>
        </Accordion>

        <Accordion title="Dados do Comprador" :expand="expand">
          <div class="flex flex-col p-2 m-2 overflow-x-auto">
            <div class="flex gap-2">
              <b>Nome:</b>
              <span class="capitalize">{{ d.nome }}</span>
            </div>
            <div class="flex gap-2">
              <b>CPF:</b>
              <span>{{ numberToCpf(d.cpf) }}</span>
            </div>
            <div class="flex gap-2">
              <b>Telefone:</b>
              <span>{{ numberToCel(d.telefone) }}</span>
            </div>
            <div class="flex gap-2">
              <b>E-mail:</b>
              <span>{{ d.email }}</span>
            </div>
            <div class="flex gap-2">
              <b>Endereço:</b>
              <span>{{ d.endereco }}</span>
            </div>
            <div class="flex gap-2" v-if="d.complemento">
              <b>Complemento:</b>
              <span>{{ d.complemento }}</span>
            </div>
            <div class="flex gap-2">
              <b>Bairro:</b>
              <span>{{ d.bairro }}</span>
            </div>
            <div class="flex gap-2">
              <b>Cidade:</b>
              <span>{{ d.cidade }} - {{ d.estado }} </span>
            </div>
            <div class="flex gap-2">
              <b>CEP:</b>
              <span>{{ numberToCep(d.cep) }} </span>
            </div>
          </div>
        </Accordion>



        <Accordion title="Dados de Leitura" :expand="expand">
          <Accordion v-for="(v, vqr) in qrcode[index]" v-bind:key="vqr" :title="`${vqr + 1}`" :expand="expand">
            <div class="flex flex-col p-2 m-2">
              <div class="flex gap-2">
                <b>Identificador:</b>
                <span class="capitalize">{{ v.identificador }}</span>
              </div>
              <div class="flex gap-2">
                <b>id:</b>
                <span class="capitalize">{{ v.id }}</span>
              </div>
              <div class="flex flex-col" v-if="v.qrcode[0]">
                <div class="flex gap-2">
                  <b>QR Code: </b>
                  <span class="capitalize">{{ v?.qrcode[0]?.qrcode }}</span>
                </div>
                <div class="flex gap-2">
                  <b>Data da leitura: </b>
                  <span class="capitalize">{{
                    new Date(v.qrcode[0].data_leitura).toLocaleDateString(
                      "pt-BR"
                    )
                  }}
                    -
                    {{
                      new Date(v.qrcode[0].data_leitura).toLocaleTimeString(
                        "pt-BR"
                      )
                    }}</span>
                </div>
                <div class="flex gap-2">
                  <b>ID do aparelho: </b>
                  <span class="capitalize">{{ v?.qrcode[0].id_aparelho }}</span>
                </div>
                <div class="flex gap-2">
                  <b>status de leitura: </b>
                  <span class="capitalize">{{ v?.qrcode[0].status }}</span>
                </div>
              </div>
              <div class="text-center font-medium flex justify-center items-center opacity-75 my-2">
                Nenhum dado de leitura encontrado...
                <i class="ml-3 gg-ghost-character"></i>
              </div>
            </div>
          </Accordion>
        </Accordion>
      </Accordion>
    </div>

    <div class="card shadow-sm border p-8 mt-3 bg-white" v-if="flag">
      <div class="text-center font-medium flex justify-center items-center opacity-75">
        Nenhum item encontrado... <i class="ml-3 gg-ghost-character"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/Elements/Input.vue";
import { defineComponent, ref, inject, onMounted, watch } from "vue";
import alert from "@/services/alert";
import { POST,GET } from "@/services/api";
import Accordion from "@/components/Elements/Accordion.vue";
import {
  numberToCpf,
  numberToCel,
  numberToCep,
  toMoney,
} from "@/services/formater";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default defineComponent({
  name: "PedidosScreen",
  components: {
    Input,
    Accordion,
    StreamBarcodeReader
  },
  data() {
    return {
      isMobile: false,
      expand: false,
      torchStatus: true,
    };
  },
  created() {
    this.isMobile = window.innerWidth < 800 ? true : false;
    window.addEventListener("resize", () => {
      this.isMobile = window.innerWidth < 800 ? true : false;
    })
  },
  methods: {
    toggle() {
      this.expand = !this.expand;
    },
  },

  setup: () => {
    const code = ref("");
    const data = ref([]);
    const vendaProduto = ref([]);
    const qrcode = ref([]);
    const flag = ref(false);
    const gotData = ref(false);
    const loader = inject("loading");
    const modalLog = inject("modalProcuraIngresso");
    const camera = ref(false)
    const erro = ref('')
    const qrcodeNaoEncontrado = ref('')
    const trava = ref(0)


    onMounted(() => {
      loader.close();
    });

    const getPedidos = async () => {
      if (code.value.length < 1) {
        console.log()
        alert.open(
          "Atenção!",
          "Digite o código da transação para buscar os pedidos.",
          "error"
        );
        return;
      } else {
        loader.open();
        data.value = [];
        vendaProduto.value = [];
        qrcode.value = [];
        try {
          const res = await POST("/pedido", { id: code.value });
          if (res.length > 0) {
            gotData.value = true;
            flag.value = false;
            for (const key in res) {
              data.value.push(res[key]);
            }

            data.value.forEach((item) => {
              item.venda_produto.forEach((i) => {
                vendaProduto.value.push({ ...i });
              });
            });

            vendaProduto.value.forEach((item) => {
              qrcode.value.push(item.qrcode);
            });

          } else {
            loader.close();
            flag.value = true;
          }
        } catch (error) {
          loader.close();
          console.log(error);
        } finally {
          loader.close();
        }
      }
    };

    const showCamera = () => {
      try {
        camera.value = !camera.value
      } catch (e) {
        console.log(e)
      }
    }

    const onDecode = async (decodedString) => {
      trava.value += 1
      if (trava.value === 1) {
        try {
          vendaProduto.value = null
          gotData.value = false
          const res = await POST("/pedido/qrcode", { id: decodedString });
          camera.value = false
          if (typeof res === 'number') {
            code.value = res
            qrcodeNaoEncontrado.value = ""
            getPedidos()
          } else {
            qrcodeNaoEncontrado.value = "Nenhuma transação relacionada a esse QRcode!!"
            code.value = ''
          }

        } catch (error) {
          loader.close();
          erro.value = error
          console.log(error);
        } finally {
          trava.value = 0
        }
      }
    }

    async function searchInFila(pedido){
      try {
        if(!pedido){
          alert.open(
            "Atenção!",
            "Digite o código da transação para buscar os pedidos.",
            "warning"
          );
          return;
        }


        loader.open();
        const res = await GET(`/fila_processo/venda/${pedido}`);

        if(res && res.success){
          // colocar no modal
          modalLog.open(JSON.stringify(res.data), false);

        }

        loader.close();
      } catch (error) {
        alert.open(
          "Atenção!",
          "Não foi possivel encontrar o pedido na Fila.",
          "error"
        );
        loader.close();
      }
    }

    async function searchInCatraca(pedido){
      try {
        if(!pedido){
          alert.open(
            "Atenção!",
            "Digite o código da transação para buscar os pedidos.",
            "warning"
          );
          return;
        }


        loader.open();
        const res = await GET(`/catraca/venda/${pedido}`);

        if(res && res.success){
          // colocar no modal
          modalLog.open(JSON.stringify(res.data), true);

        }

        loader.close();
      } catch (error) {
        alert.open(
          "Atenção!",
          "Não foi possivel encontrar o pedido na Fila.",
          "error"
        );
        loader.close();
      }
    }

    return {
      code,
      data,
      getPedidos,
      gotData,
      flag,
      numberToCpf,
      numberToCel,
      numberToCep,
      toMoney,
      vendaProduto,
      qrcode,
      camera,
      showCamera,
      onDecode,
      erro,
      qrcodeNaoEncontrado,
      searchInFila,
      searchInCatraca,
    };
  },
});
</script>

<style scoped>
.fundoQrCode {
  background-color: rgb(0, 0, 0);
  width: 500px;
}
</style>
